<!-- Finder expansible de Comunicados Expedientes -->

<template>
  <div class="exp_comunicados_XM" v-if="schema">      
    <v-sheet :elevation="4">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto -->            
        <btramto          
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto">                
        </btramto>   

        <!-- Extra -->            
        <btraextra
          v-show="is_edit"
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="mextra"
          @onEvent="event_btra_Extra">     
        </btraextra>    
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">  
        <div style="display:flex;width:800px">        
          <v-select         
            style="width:33%;white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;"               
            v-bind="$select"            
            v-model="schema.ctrls.emp.value"
            :label="schema.ctrls.emp.label"
            :items="itemsEmp"            
            item-value="id"
            item-text="name"
            :disabled="!is_edit"
            @change="change_empresa">
          </v-select>
                 
          <v-select       
            style="width:33%;white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;"       
            v-bind="$select"
            v-model="schema.ctrls.tip.value"
            :label="schema.ctrls.tip.label"
            :items="itemsComunicado"       
            item-value="id"
            item-text="name"
            :disabled="!is_edit"           
            @change="change_comunicado">
          </v-select>        
       
          <v-select
            style="width:33%;white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;"   
            v-bind="$select"
            v-model="schema.ctrls.adjuntos.value"
            :label="schema.ctrls.adjuntos.label"            
            :items="itemsAdjuntos"          
            item-value="id"
            item-text="name"          
            multiple
            :disabled="!is_edit">
          
              <template v-slot:selection="{ index }">              
                <span  
                  v-if="index=== 0"              
                  class="grey--text caption">                
                    ({{ schema.ctrls.adjuntos.value.length }} documentos adjuntos)
                </span>
              </template>
          </v-select>        
        </div>

        <div style="display:flex">          
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.ate.value"
            :label="schema.ctrls.ate.label"
            :disabled="!is_edit">          
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.sref.value"
            :label="schema.ctrls.sref.label"
            :disabled="!is_edit">          
          </v-text-field>
        </div>

        <div style="display:flex">          
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.de.value"
            :label="schema.ctrls.de.label"
            :disabled="!is_edit">          
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.nref.value"
            :label="schema.ctrls.nref.label"
            :disabled="!is_edit">          
          </v-text-field>
        </div>

        <div style="display:flex">          
          <v-text-field
            style="flex: 0 0 20%"
            v-bind="$input"
            v-model="schema.ctrls.tlf.value"
            :label="schema.ctrls.tlf.label"
            readonly>            
          </v-text-field>

          <v-text-field
            style="flex: 0 0 20%"
            v-bind="$input"
            v-model="schema.ctrls.fax.value"
            :label="schema.ctrls.fax.label"
            :disabled="!is_edit">            
          </v-text-field>

          <v-text-field
            style="flex: 0 0 60%"
            v-bind="$input"
            v-model="schema.ctrls.ema.value"
            :label="schema.ctrls.ema.label"
            :disabled="!is_edit">         
          </v-text-field>
        </div>
                
        <div v-show="estado== 'editar' || estado== 'nuevo'" class="document-editor">
          <div id="toolbar" class="document-editor__toolbar"></div>
          <div class="document-editor__editable-container">
            <div class="document-editor__editable">
              <ckeditor toolbar-container="#toolbar" type="document" v-model="schema.ctrls.txt.value"></ckeditor>          
            </div>
          </div>
        </div>         

      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";   
  import plugs from "@/common/general_plugs";  
  const btramto = () => plugs.groute("btramto.vue", "comp");  
  const btraextra = () => plugs.groute("btraextra.vue", "comp");  
      
  export default {
    mixins: [mixinMto],
    components: { btramto, btraextra },
    props: {
      padre: { type:String, default: '' },
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      auxRecord: { type:Object, default: null},
      tip: { type:Number, default:0 },
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },


    data() {
      return {
        schema: null,       
        api:'exp_Comunicados_M',
        stName:'stMexp_Comunicados',
        disparoFinder:false,

        itemsEmp:[],
        itemsComunicado:[], 
        itemsAdjuntos:[], 
        selectedEmp:null,        
        selectedComunicado:null        
      };
    },  


    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data exp_comunicados)", this.miStPadre);

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;    
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["XM"]));
        this.Entorno.btra.footer.mto[4]= 0;
        this.Entorno.btra.footer.extra= {};
        this.Entorno.btra.footer.extra= [
          { accion: "send_fax", btn: "btn_sendFax", show: 1 },
          { accion: "send_email", btn: "btn_sendEmail", show: 1 }          
        ];
                
               
        // records Comunicados
        this.itemsEmp= JSON.parse(JSON.stringify(this.$store.state[this.padre].recordsDetalle[1]));

        // records Tipos
        //this.itemsComunicado= JSON.parse(JSON.stringify(this.$store.state[this.padre].recordsDetalle[2]));
        this.itemsComunicado= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_comunicados));

        // records Documentos del Expediente
        this.itemsAdjuntos= JSON.parse(JSON.stringify(this.$store.state[this.padre].recordsDetalle[2]));        
      },
      

      record_cargado() {
        this.recordEmp(this.schema.ctrls.emp.value);
        this.recordComunicado(this.schema.ctrls.tip.value);        

        //quitado desde change_comunicado
        if (!this.schema.ctrls.sref.value) this.schema.ctrls.sref.value= this.auxRecord.callref1;
        if (!this.schema.ctrls.nref.value) this.schema.ctrls.nref.value= this.auxRecord.control;
      },


      // obtengo record empresa
      recordEmp(emp) {
        this.selectedEmp= JSON.parse(JSON.stringify(this.itemsEmp)).filter(elem=> elem.id==emp);
      },


      // obtengo record tipo comunicado
      recordComunicado(tipo) {
        this.selectedComunicado= JSON.parse(JSON.stringify(this.itemsComunicado)).filter(elem=> elem.id== tipo);
      },


      //
      change_empresa(emp) {    
        // obtengo record de Empresa
        this.recordEmp(emp);

        // filtro comunicados según la empresa indicada   
        let itemsComunicado= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_comunicados));
        this.itemsComunicado= this.selectedEmp[0].c== "0"? itemsComunicado : (itemsComunicado.filter(elem=> elem.alias== this.selectedEmp[0].c || elem.alias==""));
 
        // asigno a ctrls datos de la empresa seleccionada
        this.schema.ctrls.ate.value= this.selectedEmp[0].x;
        this.schema.ctrls.tlf.value= this.selectedEmp[0].t;
        this.schema.ctrls.fax.value= this.selectedEmp[0].f;
        this.schema.ctrls.ema.value= this.selectedEmp[0].e1;
        if (!this.schema.ctrls.sref.value) this.schema.ctrls.sref.value= this.auxRecord.callref1;
        if (!this.schema.ctrls.nref.value) this.schema.ctrls.nref.value= this.auxRecord.control;

        // cambio el comunicado al primer elemento
        this.change_comunicado(this.itemsComunicado[0].id);
      },


      // 
      async change_comunicado(tipo) {
        // obtengo record de Empresa
        this.recordComunicado(tipo);

        // obtengo datos del comunidado recibido       
        var args = {
          tipo: "fnc",
          accion: "comunicados",
          fn_args: {
            accion: "tipo",
            tip:tipo,
            exp_id: this.auxRecord? this.auxRecord.id : this.accionRow.exp,
            emp:this.itemsEmp[this.schema.ctrls.emp.value].name
          }
        };
        console.log('argfs: ', args);
        var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult: ', apiResult);
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        // actualizo datos del schema según los datos recibidos
        this.schema.ctrls.tip.value= tipo;
        this.schema.ctrls.txt.value= apiResult.r[0].txt;
        if (apiResult.r[0].ema>'') 
          this.schema.ctrls.ema.value= apiResult.r[0].ema;
        else
          this.schema.ctrls.ema.value=this.selectedEmp[0]["e" + apiResult.r[0].tpe];

        //pasado al editar o nuevo si estan vacios.

        //if (!this.schema.ctrls.sref.value) this.schema.ctrls.sref.value= this.auxRecord.callref1;
        //if (!this.schema.ctrls.nref.value) this.schema.ctrls.nref.value= this.auxRecord.control;
      },


      // envio comunicado como fax
      send_fax() {
        if (!this.schema.ctrls.fax.value) {
          this.$root.$alert.open('Debe introducir un Nº de Fax', 'info');
          return;
        }

        this.send_comunicado(1);
      },


      // envio comunicado como email
      send_email() {
        if (!this.schema.ctrls.ema.value) {
          this.$root.$alert.open('Debe introducir una dirección de Email', 'info');
          return;
        }

        this.send_comunicado(2);
      },


      // envio comunicado como FAX (1) o como EMAIL (2)
      async send_comunicado(envio) {   
        // obtengo campos del comunicado
        var ctrls= this.get_ctrls_comunicado(envio);
        ctrls['accion']= "guardar";

        // llamada a la API      
        var args = { tipo: this.$store.state[this.stName].tipo, api:this.$store.state[this.stName].api, accion: 'send_comunicado', fn_args: { ctrls:ctrls }};                
        console.log('args send_comunicado: ', args);
        var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult send_comunicado: ', apiResult);
        //
        if (apiResult.sql.error) {          
          this.$root.$alert.open(apiResult.sql.msg, "error");
          return false;
        }

        this.$root.$alert.open("Comunicado enviado!", "success", 1800);

        // si es un nuevo, actualizo id
        if (this.estado== 'nuevo') this.accionRow.id= apiResult.r[0][0].id;  

        // actualizo records del finder Padre y cierro el expansible
        this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: apiResult.r[0][0], id: this.accionRow.id });

        // actualizo estado
        this.$store.commit(this.stName + '/data2State', { prop:'estado', value:'ver' });
      },


      // monto los campos del comunicado y los devuelvo
      get_ctrls_comunicado(envio) {
        // obtengo par-valor de los controles del schema     
        var ctrls= this.ctrl2linea(this.schema.ctrls);
       console.log('auxRecord: ', this.auxRecord);
        // añado datos a los controles      
        ctrls['exp_id']= this.auxRecord? this.auxRecord.id : this.accionRow.exp;
        if (Object.keys(this.selectedEmp).length && (this.selectedEmp[0].c== 'CIA' || this.selectedEmp[0].c== 'CTE')) {
          ctrls['asu']= "Ref. " + this.auxRecord.callref1;

        }else {
          ctrls['asu']= !Object.keys(this.selectedComunicado).length? "" : (!this.selectedComunicado[0].asu? this.selectedComunicado[0].name : this.selectedComunicado[0].asu);
        }
       
        ctrls['emp']= Object.keys(this.selectedEmp).length? this.selectedEmp[0].name : "";
        ctrls['env']= envio;
        ctrls['tip']= this.schema.ctrls.tip.value;
        ctrls['adj']= this.schema.ctrls.adjuntos.value;
        return ctrls;
      },


      // convierto string de índices de documentos seleccionados en array
      select_adjuntos({ ctrl, record }) {
        if (typeof record[ctrl.f]=== "undefined" || !record[ctrl.f]) return [];
        if (record[ctrl.f].indexOf('.')>= 0) return [];
        
        ctrl.value= record[ctrl.f].split(',');
      }


    }
};
</script>



<style>
  .document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);

    /* Set vertical boundaries for the document editor. */
    max-height: 700px;

    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
  }

  .document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    width:800px;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
}

.document-editor__editable-container {
    padding: calc( 2 * var(--ck-spacing-large) );
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
    /* Set the dimensions of the "page". */
    width: 20.8cm;
    min-height: 21cm;

    /* Keep the "page" off the boundaries of the container. */
    padding: 1cm 2cm 2cm;

    border: 1px hsl( 0,0%,82.7% ) solid;
    border-radius: var(--ck-border-radius);
    background: white;

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

    /* Center the "page". */
    margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
    min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 2.18em;
    font-weight: normal;
}

.document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 1.75em;
    font-weight: normal;
    color: hsl( 203, 100%, 50% );
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
}

.document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em;
}
</style>